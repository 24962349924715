import React from 'react'
import Slider from 'react-slick'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { onDesktop } from '../../../../styles/theme-proven/responsive'
import { createProvenTheme } from '../../../../styles/theme-proven'

const themeProven = createProvenTheme()

const useStyles = makeStyles(theme => ({
  slider: {
    marginBottom: '38px',
    '& .slick-list': {
      margin: '0 30px'
    }
  },
  nextPrevArrow: {
    display: 'block',
    boxSizing: 'border-box',
    height: '12px',
    width: '12px',
    transform: 'rotate(-45deg)',
    top: '84vw',
    position: 'absolute',
    cursor: 'pointer',
    zIndex: '1',
    [theme.breakpoints.up('sm')]: {
      height: '19px',
      width: '19px'
    },
    [onDesktop]: {
      top: '50%'
    }
  },
  nextArrow: {
    borderRight: `2px solid ${themeProven.palette.gray.elysian}`,
    borderBottom: `2px solid ${themeProven.palette.gray.elysian}`,
    right: '8px',

    [theme.breakpoints.up('md')]: {
      right: '15px'
    }
  },
  prevArrow: {
    borderLeft: `2px solid ${themeProven.palette.gray.elysian}`,
    borderTop: `2px solid ${themeProven.palette.gray.elysian}`,
    left: '8px',

    [theme.breakpoints.up('md')]: {
      left: '15px'
    }
  }
}))

export default function BeforeAfterSlide({ children }) {
  const classes = useStyles()

  function NextArrow(props) {
    const { onClick } = props
    return <div className={`${classes.nextPrevArrow} ${classes.nextArrow}`} onClick={onClick} />
  }

  function PrevArrow(props) {
    const { onClick } = props
    return <div className={`${classes.nextPrevArrow} ${classes.prevArrow}`} onClick={onClick} />
  }

  const sliderSettings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    swipe: false,
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 959,
        settings: {
          centerPadding: '160px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <Box
      sx={{
        width: '100%',
        // ml: { xs: -2, md: -8, lg: '-150px', xxl: '-12.5%' },
        '& .slick-list': {
          paddingBottom: '126px !important',
          paddingTop: '24px !important',
          [onDesktop]: {
            paddingBottom: '130px !important',
            paddingTop: '48px !important'
          }
        },
        '& .slick-dots': {
          bottom: '80px',
          '& li': {
            margin: 0,
            '& button:before': {
              opacity: 0.4300000071525574,
              color: '#256376'
            }
          },
          '& li.slick-active button:before': {
            opacity: 1
          }
        }
      }}
    >
      <Slider {...sliderSettings}>{children}</Slider>
    </Box>
  )
}
